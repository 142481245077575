<template>
    <div v-if="overdue">
        <template v-if="grades.length && !fetching">
            <div class="d-flex align-center">
                <div class="text-subtitle-1">Grade Table</div>
                <v-spacer />
                <v-btn text color="primary" @click="exportGrades">
                    <v-icon class="mr-1">download</v-icon>
                    Export to xlsx
                </v-btn>
            </div>
            <v-data-table
                :headers="headers"
                :items="gradesAndApps"
                group-by="Status"
                loading-text="Loading... Please wait"
                disable-pagination
                hide-default-footer
                :show-group-by="false"
                sort-by="handin_long_id"
                class="grade-table">
                <template v-slot:[`item.handin_long_id`]="props">
                    <span :class="props.item.Status === gradeStatus.CURRENTLY_GRADING ? 'font-weight-bold' : ''">{{ props.item.handin_long_id }}</span>
                    <v-chip v-if="props.item.Status === gradeStatus.CURRENTLY_GRADING" color="info" small class="mx-2">Opened</v-chip>
                    <span v-else-if="props.item.Status === gradeStatus.UNGRADED" class="mx-1 grey--text"><v-icon>help</v-icon></span>
                    <span v-else-if="props.item.Status === gradeStatus.GRADED"><v-icon class="mx-1 success--text">done</v-icon></span>
                </template>
                <template v-slot:[`item.grade`]="props">
                    <v-edit-dialog :return-value.sync="props.item.grade" @save="save(props.item.hid, props.item.grade)">
                        <span :class="props.item.Status === gradeStatus.CURRENTLY_GRADING ? 'font-weight-bold' : ''">
                            {{ props.item.grade || 'Click to Add Grade' }}
                        </span>
                        <v-icon small class="ml-1" style="opacity: 0.75">edit</v-icon>
                        <template v-slot:input>
                            <v-text-field v-model="props.item.grade" label="Edit" single-line counter outlined></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.open_with`]="{ item }">
                    <v-btn
                        text
                        color="primary"
                        v-if="!(item.Status === gradeStatus.CURRENTLY_GRADING && $route.name === 'app-open') && item.open_with.length === 1"
                        @click="
                            gotoApp(item.open_with[0].iid, item.open_with[0].snid, item.open_with[0].aid, item.open_with[0].aoid, item.handin_long_id, item.hid)
                        ">
                        <v-icon class="mr-1">visibility</v-icon>
                        Review
                    </v-btn>
                    <v-menu offset-y v-else-if="!(item.Status === gradeStatus.CURRENTLY_GRADING && $route.name === 'app-open') && item.open_with.length > 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" color="primary">
                                <v-icon class="mr-1">visibility</v-icon>
                                Review
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="a in item.open_with" :key="a.aid" @click="gotoApp(a.iid, a.snid, a.aid, a.aoid, item.handin_long_id, item.hid)">
                                <v-list-item-title>{{ a.application_long_id }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </template>
        <v-skeleton-loader v-else-if="fetching" type="table"></v-skeleton-loader>
        <v-alert type="warning" class="mt-4 mb-0" v-if="no_handins.length" text>
            The following instances have no submissions:
            <ul class="mt-2">
                <li v-for="i in no_handins" :key="i">
                    {{ i }}
                </li>
            </ul>
        </v-alert>
        <v-dialog v-model="showNextInstance" max-width="500">
            <v-card>
                <v-card-title>
                    <v-icon color="success" class="mr-1">done</v-icon>
                    Grade saved
                    <v-spacer />
                    <v-icon @click="showNextInstance = false">close</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                    <div v-if="nextReviewItem">
                        Grade saved, open next ungraded handin?
                        <div class="mt-2">
                            <v-btn color="primary" @click="gotoNextApp()" text>
                                <v-icon>navigate_next</v-icon>
                                Continue
                            </v-btn>
                        </div>
                    </div>
                    <div v-else>
                        Congratulations, all handins have been graded!
                        <p class="mt-2">To make the results visible to students, edit the assignment configuration and enable handback visibility.</p>
                        <TheBundleEditDialog :bundleData="bundleData" highlightHandBack>
                            <template v-slot:dialogactivator>
                                <v-btn color="primary">
                                    <v-icon class="mr-1">edit</v-icon>
                                    Enable Visibility
                                </v-btn>
                            </template>
                        </TheBundleEditDialog>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
    <div v-else>Hand-ins are still possible for this assignment, grading can only be started once the assignment deadline has passed.</div>
</template>

<script>
import { isMasterInstance, isDistributedInstance } from '@/utils'
import { sortBy, uniq } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import Shepherd from 'shepherd.js'
import XLSX from 'xlsx'
import tour from '@/mixins/tour'
const TheBundleEditDialog = () => import('./TheSnapshotBundleEditDialog')

export default {
    components: { TheBundleEditDialog },
    mixins: [tour],
    props: {
        bid: Number,
        overdue: Boolean,
        bundleData: Object,
        startTour: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            grades: [],
            no_handins: [],
            headers: [
                {
                    text: 'Hand-in name',
                    align: 'start',
                    sortable: true,
                    value: 'handin_long_id'
                },
                { text: 'Grade', value: 'grade' },
                { text: 'Application', value: 'open_with' }
            ],
            fetching: false,
            spaceApps: [],
            gradeStatus: {
                CURRENTLY_GRADING: 'a) Currently Grading',
                UNGRADED: 'b) Ungraded',
                GRADED: 'c) Graded'
            },
            changingVisibility: false,
            handBacksVisible: this.bundleData.handbacks_visible,
            showNextInstance: false,
            tourId: 'seen_grading_tour',
            tourHidden: true
        }
    },
    watch: {
        '$route.params.iid': {
            handler(to) {
                this.fetching = true
                this.$axios
                    .get(`/bundles/${this.bid}/grades`)
                    .then(r => {
                        this.grades = r.data
                            .filter(h => !isMasterInstance(h.instance_long_id) && !isDistributedInstance(h.instance_long_id) && h.handin_long_id)
                            .map(h => {
                                h.Status = this.gradeStatus.UNGRADED
                                if (h.iid.toString() === this.$route.params.iid.toString() && h.handin_long_id) {
                                    h.Status = this.gradeStatus.CURRENTLY_GRADING
                                } else if (h.grade) {
                                    h.Status = this.gradeStatus.GRADED
                                }
                                return h
                            })

                        this.no_handins = sortBy(
                            uniq(
                                r.data
                                    .filter(h => !isMasterInstance(h.instance_long_id) && !isDistributedInstance(h.instance_long_id) && !h.handin_long_id)
                                    .map(i => i.instance_long_id)
                            ),
                            [i => i]
                        )
                    })
                    .finally(() => {
                        this.fetching = false
                    })
            },
            immediate: true
        },
        startGradingTour: {
            handler: function (to) {
                if (to) {
                    this.$nextTick(() => {
                        this.tour = new Shepherd.Tour({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                cancelIcon: {
                                    enabled: true
                                },
                                scrollTo: { behavior: 'smooth', block: 'center' }
                            }
                        })

                        this.tour.addSteps([
                            {
                                title: 'Starting a review',
                                text: `Grading assignments in Nuvolos is simple!\
  By clicking 'Review' on a hand-in below, you can launch an application and edit / comment in the assignment files. \
  The original versions are stored for reference.`,
                                attachTo: {
                                    element: '.grade-table',
                                    on: 'top'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'review'
                            },
                            {
                                title: 'Adding a grade',
                                text: `Once you are done with one review, you can come back to this table and add the grade.\
                            You can proceed easily across all hand-ins.`,
                                attachTo: {
                                    element: '.grade-table',
                                    on: 'top'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'grade'
                            },
                            {
                                title: 'Handing back corrections',
                                text: `Once you are done with all reviews, you will be able to reveal the grades to students \
                            who can then check their grades and your edited hand-back files.`,
                                attachTo: {
                                    element: '.grade-table',
                                    on: 'top'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.complete()
                                        },
                                        text: 'Got it!'
                                    }
                                ],
                                id: 'handback'
                            }
                        ])

                        this.tour.start()
                    })
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.$axios.get(`/spaces/${this.$route.params.sid}/applications`, { params: { only_development: true } }).then(r => {
            this.spaceApps = r.data
        })
    },
    computed: {
        gradesAndApps() {
            return this.grades.map(g => {
                g.open_with = this.spaceApps.filter(
                    s => s.iid.toString() === g.iid.toString() && (!this.$route.query.g_aoid || s.aoid.toString() === this.$route.query.g_aoid.toString())
                )
                return g
            })
        },
        nextReviewItem() {
            const nextItems = this.gradesAndApps.filter(g => {
                return g.Status === this.gradeStatus.UNGRADED && g.iid.toString() !== this.$route.params.iid.toString()
            })
            if (nextItems.length > 0) {
                return sortBy(nextItems, [i => i.handin_long_id])[0]
            }
            return null
        },
        startGradingTour() {
            return this.startTour && this.grades.length && !this.fetching && !this.userMetadata.seen_grading_tour
        },
        ...mapGetters('instanceStore', ['isMasterInstance']),
        ...mapState('appStore', ['deploymentStatus']),
        ...mapState(['userOrgs', 'userMetadata'])
    },
    methods: {
        save(hid, content) {
            this.$axios
                .post(`/handins/${hid}/grade`, { content })
                .then(r => {
                    this.showNextInstance = true
                })
                .catch(e => {
                    this.$store.dispatch('showSnackBar', { snackBarText: 'Saving failed!', snackBarIcon: 'error' })
                })
        },
        gotoApp(iid, snid, aid, aoid, handinLongId, hid) {
            this.$emit('closeModal', '')
            this.$router.push({
                name: 'app-open',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid,
                    snid,
                    aid
                },
                query: { grading: this.bid, g_aoid: aoid, handback_hid: hid }
            })
            this.$store.dispatch('showSnackBar', { snackBarText: `Opening ${handinLongId} for review` })

            // pre-launch app for next ungraded instance that differs from the one being opened
            const nextAid = sortBy(
                this.gradesAndApps.filter(g => {
                    return g.Status === this.gradeStatus.UNGRADED && g.handin_long_id !== handinLongId
                }),
                [i => i.handin_long_id]
            )

            if (nextAid.length > 0 && nextAid[0].open_with.length > 0) {
                const appToOpen = nextAid[0].open_with.find(a => a.aoid.toString() === aoid.toString())
                if (
                    this.deploymentStatus.filter(d => {
                        return (
                            d.aid.toString() === appToOpen.aid.toString() &&
                            d.mount_handback_to_files_for_hid &&
                            d.mount_handback_to_files_for_hid.toString() === hid.toString()
                        )
                    }).length === 0
                ) {
                    const scaleFactor = 1
                    this.$axios.post(`/proxy/${appToOpen.aid.toString()}/start_app`, {
                        dpi: 96 * scaleFactor,
                        screen_width: (window.innerWidth - 65) * scaleFactor,
                        screen_height: window.innerHeight * scaleFactor,
                        app_username: '',
                        app_pwd: '',
                        node_pool: '',
                        shared: false,
                        mount_handback_to_files_for_hid: nextAid[0].hid
                    })
                }
            }
        },
        gotoNextApp() {
            this.gotoApp(
                this.nextReviewItem.open_with[0].iid,
                this.nextReviewItem.open_with[0].snid,
                this.nextReviewItem.open_with[0].aid,
                this.nextReviewItem.open_with[0].aoid,
                this.nextReviewItem.handin_long_id,
                this.nextReviewItem.hid
            )
        },
        exportGrades() {
            const grades = XLSX.utils.json_to_sheet(
                sortBy(
                    this.gradesAndApps.map(g => {
                        const o = {}
                        o.instance_long_id = g.instance_long_id
                        o.handin_long_id = g.handin_long_id
                        o.grade = g.grade
                        return o
                    }),
                    [i => i.instance_long_id]
                )
            )

            const wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, grades, 'grades')

            // export Excel file
            XLSX.writeFile(wb, 'grades.xlsx')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-row-group__header {
    background: white !important;
}
</style>
